export default [
  {
    title: 'Início',
    icon: 'HomeIcon',
    route: 'fut-home',
    resource: 'assinante-futures',
  },
  {
    title: 'Ordens abertas',
    icon: 'ShoppingCartIcon',
    route: 'fut-ordem-aberta',
    resource: 'assinante-futures',
  },
  {
    title: 'Posições',
    icon: 'TrendingUpIcon',
    route: 'fut-posicao',
    resource: 'assinante-futures',
  },
  {
    title: 'Transferência',
    icon: 'CornerDownRightIcon',
    route: 'fut-transfer',
    resource: 'assinante-futures',
  },
  {
    title: 'Meus agentes',
    icon: 'UsersIcon',
    route: 'fut-meu-agente',
    resource: 'assinante-futures',
  },
  {
    header: 'Relatórios',
    icon: 'ListIcon',
    children: [
      {
        title: 'Fechamento',
        route: 'fut-relatorio-fechamento',
        resource: 'assinante-futures',
      },
    ],
  },
  {
    title: 'Sinais',
    icon: 'ActivityIcon',
    route: 'fut-relatorio-sinal',
    resource: 'assinante-futures',
  },
  {
    header: 'Agente',
    icon: 'UserIcon',
    children: [
      {
        title: 'Assinantes',
        route: 'fut-agente-assinante',
        resource: ['agente-futures', 'agente-futures-robo'],
      },
      {
        title: 'Desempenho assinantes',
        route: 'fut-agente-assinante-desempenho',
        resource: ['agente-futures', 'agente-futures-robo'],
      },
      {
        title: 'Nova operação',
        route: 'fut-agente-nova-operacao',
        resource: 'agente-futures',
      },
      {
        title: 'Operações abertas',
        route: 'fut-agente-operacao-aberta',
        resource: 'agente-futures',
      },
      {
        title: 'Aprovar operações',
        route: 'fut-monitor-aprovar-operacao',
        resource: 'agente-futures-robo',
      },
      {
        title: 'Monitorar operações',
        route: 'fut-monitor-operador-estrategia-instancias',
        resource: 'agente-futures-robo',
      },
    ],
  },
  {
    header: 'Afiliado',
    icon: 'UserIcon',
    children: [
      {
        title: 'Sessão usuários',
        route: 'fut-afiliado-sessao-usuario',
        resource: 'afiliado',
      },
    ],
  },
  {
    title: 'Ajustes',
    icon: 'SettingsIcon',
    route: 'conta-configuracao',
    resource: 'assinante-futures',
  },
  {
    header: 'Indicações',
    icon: 'Share2Icon',
    children: [
      {
        title: 'Dashboard',
        route: 'indicacao',
        resource: 'multinivel',
      },
      {
        title: 'Extrato',
        route: 'indicacao-extrato',
        resource: 'multinivel',
      },
      {
        title: 'Rede',
        route: 'indicacao-rede',
        resource: 'multinivel',
      },
      {
        title: 'Saque',
        route: 'indicacao-saque',
        resource: 'multinivel',
      },
    ],
  },
  {
    header: 'Controle',
    icon: 'UserIcon',
    children: [
      {
        title: 'Assinantes',
        route: 'controle-assinante',
        resource: 'administrador',
      },
    ],
  },
]
